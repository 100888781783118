import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: November 22, 2019</p> */}


<h1 className="text-3xl m-0 mb-6 font-extrabold tracking-normal sm:text-4xl text-center text-gray-500 uppercase " style={{ color: 'var(--marketplaceColor)' }}>
         Terms of Service
          </h1>

<p>Vivacity, and its predecessors, successors, licensors, beneficiaries and/or affiliates (collectively, “Vivacity.Studio”) welcome you to Vivacity.Studio (the “Site”), which includes all web pages within the Site. By using the Site, you agree to be bound by these Terms, whether or not you register as a Member of the Site. If you do not agree to all of the Terms, do not use the Site.</p>
<p>Vivacity.Studio may revise and update these Terms at any time. Your continued use of the Site will mean you accept the revised Terms.</p>
<p>These Terms were last updated on August 09, 2022.</p>

<p>Important disclaimer: The information provided through this site or sent to you by a vivacity.Studio trainer is intended to assist you in your fitness efforts. You agree and acknowledge that vivacity.Studio is not a medical organization, hospital or staffed by medically trained personnel. The information provided through this site is not intended as a substitute for medical counseling, or the professional advice of your personal physician. Before you begin any fitness program, consult your physician to determine if the fitness program is right for your needs. Do not start a fitness program if your physician advises against it. Please note that the site’s trainers, affiliates or employees cannot provide you with medical advice and nothing that you may read on this site or that is otherwise provided to you by the site’s trainers, affiliates or employees should be construed as such. Although vivacity.Studio and the site’s trainers, affiliates and employees makes an effort to provide quality information to you, vivacity.Studio disclaims any implied guarantee regarding the accuracy, completeness, timeliness, or relevance of any information provided through this site or sent to you by a vivacity.Studio trainer or the site. For example, health & fitness advice is often subject to updating and refining due to medical research and developments. No assurance can be given that the information provided through this site will reflect the most recent findings or developments with respect to the particular material. The site is intended for use only by healthy adult individuals. The site is not intended for use by minors or individuals with any type of health condition. Such individuals are specifically advised to seek professional medical advice prior to initiating any fitness program.</p>

<h2>Eligibility</h2>
<p>Use of the site is limited to individuals 18 years of age or older. Registrants will need sufficient fluency in the language in which the online class is presented. By accepting this agreement, you are affirming that you are 18 years of age or older. Vivacity.Studio will not be held liable, so far as the law permits, in any way for use of this site by persons under the age of 18.</p>

<h2>Payment / Registration</h2>
<p>There is no cost to register as a Member of the Site. You must register as a Member in order to attend online classes or to be a Vivacity.Studio trainer (a “Trainer”). Vivacity.Studio reserves the right, at its sole discretion, to reject Trainer applicants. Members who wish to become a Trainer acknowledge that they will be subject to, and must agree in writing to comply with, additional terms and conditions. The Site respects your privacy and a complete statement of the Site’s current privacy policy can be found on our privacy policy page. The Site’s privacy policy is expressly incorporated into these Terms by this reference. In becoming a Member with the intent of using a fitness program, you represent and warrant to Vivacity.Studio that all of the personal information you provided during the registration process is true and correct. Vivacity.Studio reserves the right to refuse or cancel your member status or use of the site if vivacity.Studio determines that you have or if you inform us that you have certain conditions. Vivacity.Studio reserves the right to cancel your member status should you violate these terms and any other posted policy on the site.</p>
<p>The fee for the online class (the “Fee”) must be paid in full at the time of registration. Trainees agree to pay the fees and any other charges incurred in connection with their username and password (including any applicable taxes) at the rates in effect when the charges were incurred. After successful registration, trainees will receive an e-mail from Vivacity.Studio acknowledging their enrollment in the online class.</p>
<p>The Site uses Stripe to process your credit card transactions. We will bill all charges automatically to your credit card or Stripe account. Courses will be billed in United States Dollars (USD). Accepted payment methods are Visa, Mastercard, American Express, Discover, JCB, Diners Club, China UnionPay, and debit cards.</p>
<p>You agree to provide accurate and complete registration information. Submission of your registration, alongside full payment of the Fee constitute your acceptance of these Terms and Conditions.
</p> 
<p>For trainers: When an online class sells, there is a 20% commission fee on the sale price. This fee does not include the 3.5% transaction fee that is applied by the payment gateways.</p> 

<h2>Cancellations and Refunds</h2>
<p>Cancellation by trainees: If you cancel your registration in an online class, you will not be entitled to a refund, except in exceptional circumstances, which will be entirely at the discretion of Vivacity. If a refund is granted to you, an administration fee may be charged. To request such cancellation, please inform us in writing by sending an email to contact@Vivacity.Studio
<br />
Cancellation by trainers: Any online class created by a trainer utilizing Vivacity.Studio as a marketplace is subject to the refund policy stated on their checkout page. Vivacity.Studio reserves the right to cancel the online class by giving students notice by email at any time before the class is due to start. Vivacity.Studio will refund all fees paid by students and if possible, will endeavor to offer a transfer to another class as an alternative, subject to payment or refund of any difference in purchase price. Vivacity’s liability when it cancels an online class will be limited to a refund of the Fee or any other charges paid for the cancelled online class. Please write to us by sending an email to contact@Vivacity.Studio to arrange your refund. You should allow up to 6 weeks for the credit to appear on your credit card statement.
</p>
<h2>Course Content</h2>
<p>The course description is intended to indicate only the general nature of the class and does not guarantee content. </p>

<h2>Student Conduct</h2>
<p>Vivacity.Studio grants students access to online classes they are enrolled in. As a condition of such access, students agree that:<br />
- All materials – including but not limited to text, photographs, video, audio, images and any other content – appearing via the online class and/or on the Website are protected by copyrights and/or other proprietary rights belonging to Vivacity.Studio and/or other third parties. You agree to abide by all applicable laws regarding copyrights, trademarks, publicity rights and privacy rights in the US, UK and any other jurisdiction from which you are accessing the Website.<br />
- You may not copy, reproduce, duplicate, distribute, disseminate, publish, post, display, perform, modify, create derivative works from, upload to, transmit, or in any way exploit any content from the online class and/or on the Website.  You may not sell or offer for sale any of the content, or allow third parties to access it, or use such content to construct any kind of database. You may use the content from the online class and/or on the Website only for personal, non-commercial use.<br />
- Any violation of these terms may result in your expulsion from the online class and/or Vivacity, and you may be subjected to fines and penalties.<br />
- You agree to maintain the security of any username and password required for accessing the online class. You will notify us promptly if you become aware of any unauthorized use of your username and/or password.<br />
- Vivacity.Studio makes no claim that the online class content is appropriate for any particular purpose or audience, or that it may be downloaded. Access to the online class may not be legal by certain persons or in certain countries. When accessing the online class and/or the Website, you are responsible for compliance with the laws of your jurisdiction.<br />
- Vivacity.Studio reserves the right to remove you from the online class and/or the Website if you do not respect these guidelines, Vivacity.Studio teachers and/or fellow students.
<br />
  </p>
<h2>Trainers</h2>
<p>Vivacity.Studio does not endorse or recommend any trainer on the site. Any statements, programs, opinions, or other information that may be provided to you by a trainer are solely attributable to the trainer - not Vivacity.Studio or the site. Reliance on any information provided by a trainer on or through the site is solely at your own risk. Participating in any exercise program can cause injury, and you elect to do so entirely at your own risk and exempt Vivacity.Studio, the creator of the program or plan, or the trainer assigning the program or plan of any liability. Before you begin any fitness programs, consult your physician to determine if the fitness programs are right for your needs. Vivacity.Studio makes no representations or warranties as to the conduct, ability or the efficacy, accuracy, completeness, timeliness or relevance of the information provided by the Vivacity.Studio trainers and/or the services provided by said trainer or by third parties featured on the site including but not limited to, celebrity trainers. In no event shall Vivacity.Studio be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or anyone else in connection with the use of the site, including without limitation, bodily injury, emotional distress, and/or any other damages resulting from your use of any information, program or suggestion provided to you by a trainer or communications or meetings between trainers and members of or any other persons you meet through the site. You agree to take reasonable precautions in all interactions with trainers, celebrity trainers, third parties and other members, particularly if you decide to meet offline or in person. 
</p>
<h2>Delivery Method and Timing</h2>
<p>Vivacity.Studio offer online classes that do not require physical delivery. Upon completion of proper signup procedures and any required payment processing then we will make every effort to ensure that you have immediate access to your online class.
</p>
<h2>Data Protection and Account Security</h2>
<p>By registering with Vivacity.Studio and by paying the fees, you agree to Vivacity.Studio collecting and using your personal data. This will be done in accordance with the Data Protection Act 1998.
You are responsible for maintaining the confidentiality of your username and password that you designate during the registration process and you are fully responsible for all activities that occur under your username and password. You shall immediately notify us of any unauthorized use of your username or password or any other breach of security. Vivacity.Studio will not be liable for any loss or damage arising from your failure to comply with this provision. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
</p>
<h2>Technology Requirements</h2>
<p>The online class is facilitated through Zoom. It is your responsibility to ensure that you have the proper hardware and software requirements, along with access to Wi-Fi, to be able to participate in the class. You will need to ensure you have the proper technology at the time of registration as will be provided with the registration details
Vivacity.Studio is not be responsible for any errors or failures in relation to your ability to access the online class, the Website or any related materials, including where such errors or failures are caused by: (i) a loss of connection on Vivacity’s or your end; (ii) a breakdown or problems with the online software and/or (iii) a breakdown or problems with your internet connection, computer or system.</p>

<h2>Miscellaneous</h2>
<p>Vivacity.Studio may assign its rights and obligations under these Terms. These Terms will inure to the benefit of Vivacity.Studio’s successors, assigns and licensees. The failure of either party to insist upon or enforce the strict performance of the other party with respect to any provision of these Terms, or to exercise any right under the Terms, will not be construed as a waiver or relinquishment to any extent of such party’s right to assert or rely upon any such provision or right in that or any other instance; rather, the same will be and remain in full force and effect. Should a situation arise that is not covered by these Terms and Conditions, Vivacity.Studio reserves the right to take any reasonable and fair action that we think appropriate. Should you have any queries on the terms and conditions, please contact Vivacity.Studio on contact@Vivacity.Studio
</p>
      
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
